import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableResponsive from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import Text from 'antd/lib/typography/Text';
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


let fd = '';
let td = '';
export default function SpgStatement(props) {
    const spgStatement = useStoreState((state) => state.saccount.spgStatement);
    const fetchspgStatement = useStoreActions((state) => state.saccount.fetchspgStatement);
    const loading = useStoreState((state) => state.saccount.loading);



    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fetchspgStatement(postData);
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
    };

    const columns = [

        {
            title: 'Tran. Number',
            dataIndex: 'transactionId',
            key: 'transactionId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Req. Number',
            dataIndex: 'refTranNo',
            key: 'refTranNo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Req. Date',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Applicant',
            dataIndex: 'applicantName',
            key: 'applicantName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Tran. Amount',
            dataIndex: 'transactionAmount',
            key: 'transactionAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fees Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Pay Mode',
            dataIndex: 'payMode',
            key: 'payMode',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'requestStatus',
            key: 'requestStatus',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];


    return (
        <>
            <Card title="SPG Statement">
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {spgStatement?.length > 0 && !loading && 
                    <>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: spgStatement,
                                filterData: spgStatement,
                                pagination: true,
                                bordered: true,
                                rowKey: "requestLogId",
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">

                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`SPG statment from ${fd} to ${td}`}
                            >
                                <ExcelSheet data={spgStatement} name="SPG Statement">
                                    <ExcelColumn label="Tran. Number" value="transactionId" />
                                    <ExcelColumn label="Req. Number" value="refTranNo" />
                                    <ExcelColumn label="Req. Date" value="transactionDate" />
                                    <ExcelColumn label="Applicant" value="applicantName" />
                                    <ExcelColumn label="Tran. Amount" value="transactionAmount" />
                                    <ExcelColumn label="Fees Amount" value="feeAmount" />
                                    <ExcelColumn label="Pay Mode" value="payMode" />
                                    <ExcelColumn label="Status" value="requestStatus" />

                                </ExcelSheet>
                            </ExcelFile>
                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
