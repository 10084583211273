import React, { useEffect } from 'react';
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "./store/store";
import { Badge, Button, Card, Col, List, Popover, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { BellOutlined, KeyOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { useStoreState } from './store/hooks/easyPeasy';
import { Link, useHistory } from "react-router-dom";
import userp from "../assets/images/user.png";
import moment from 'moment';

export default function TopNavBar(props: any) {
  const history = useHistory();
  var isMobile = false; //initiate as false
  if (
    /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }

  const logout = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logout
  );
  const logoutclear = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logoutclear
  );

  const user = useStoreState(state => state.auth.user)
  const instituteInfo = useStoreState(state => state.auth.instituteInfo)
  const fetchMasterSettingPayabledList = useStoreActions<any>((state) => state.masterSettings.fetchMasterSettingPayabledList);
  const masterSettingPayabledList = useStoreState((state) => state.masterSettings.masterSettingPayabledList);

  const [dueAmount, setDueAmount] = React.useState<any>(0);
  const [count, setCount] = React.useState<any>(0);
  
  useEffect(() => {
    fetchMasterSettingPayabledList(instituteInfo?.instiltuteId);
}, []);

  useEffect(() => {
    if (masterSettingPayabledList?.length > 0) {
      setCount(masterSettingPayabledList?.length);
      let due = masterSettingPayabledList.reduce(function (acc, obj) {
        return acc + obj.payableAmount;
      }, 0);
      setDueAmount(due);
    }
  }, [masterSettingPayabledList]);

  const duepopHover = () => (
    <div
      style={{ width: 320, textAlign:'center' }}
      className="topbarDropDown"
  
    >
      {dueAmount > 0 ?
        <>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>The dfgasdgfsd total amount due as of the Bill date is <br /> <span style={{ color: "red" }}>৳{dueAmount}</span>  </p>

          <div style={{ textAlign: 'center' }} > <Link to="/payable-bill"> <div>Payable Bill </div></Link></div>
        </> :
        <>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>You don't have any unpaid bill at the moment. </p>
        </>
      }
    
      </div>

  );




  const logoutfunc = () => {
    logoutclear(user?.access_token);
    logout("");
  }

  const toggleValueChange = (val) => {
    return val;
  }

  useEffect(function () {
    let topHeaderWidth: any = document.getElementById('topFixedBar');
    const div = document.querySelector('#mySider');
    setTimeout(() => {
      let check = div?.classList.contains('ant-layout-sider-collapsed');
      if (!isMobile) {
        if (check == true) {
          topHeaderWidth?.classList.remove("menu-open");
          topHeaderWidth?.classList.add("menu-close");
        } else {
          topHeaderWidth?.classList.remove("menu-close");
          topHeaderWidth?.classList.add("menu-open");
        }
      } else {
        topHeaderWidth?.classList.add("menu-mobile");
        topHeaderWidth?.classList.remove("menu-close");
        topHeaderWidth?.classList.remove("menu-open");
      }
    }, 100);

  }, [toggleValueChange])

  const profilePopover = (onLogout, userData) => (
    <div
      style={{ width: 300 }}
      className="topbarDropDown"
    >
      <div className="profile-sort-details-wrapper">
        <div className="profile-image">
          {instituteInfo?.userPhoto === '' ? <img src={userp} alt="User Photo" /> : <img src={"data:image/png;base64," + instituteInfo?.userPhoto} alt="User Photo" />}
        </div>
        <div className="user-name text-center">
          <h4>{instituteInfo?.userName}</h4>
          {/* <span>{instituteInfo?.instituteEmail}</span> */}
        </div>
        <ul>
          <li className="headerDrop"><Link to="/change-password" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} ><KeyOutlined /> <div>Change Password</div></Link></li>
          <li className="headerDrop" onClick={onLogout}><div><LogoutOutlined /> <span>Logout</span></div></li>
        </ul>
      </div>
    </div>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="insInfo" style={{ width: window.screen.width - 70, display: 'flex', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", alignItems: "center" }}>
          {React.createElement(props.value.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggleValueChange(props.value.toggle),
            style: { marginLeft: 2, marginRight: 2, fontSize: 20, color: "#686F7A", },
          })}
          {isMobile && props.value.collapsed &&
      
        <>
              <Avatar
                size={{ xs: 35, sm: 35, md: 35, lg: 35, xl: 40, xxl: 45 }}
                src={"data:image/png;base64," + instituteInfo?.imageName}
                style={{ marginLeft: 10 }}
              />
             <span className='instiute-name' onClick={()=>history.push('/')} style={{ whiteSpace:"nowrap", cursor:"pointer", fontSize: isMobile ? 14 : 18, fontWeight: "bold", color: 'red', textTransform: "uppercase", letterSpacing: isMobile ? 0 : 1, marginLeft: 2 }}>{instituteInfo?.instituteName}</span> 
             </>
     
          }
          {isMobile && !props.value.collapsed &&
            <>
              {React.createElement(props.value.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggleValueChange(props.value.toggle),
                style: { marginLeft: 2, marginRight: 2, fontSize: 25, fontWeight: 'bold', color: "#0078CF", right: 70, position: "absolute" },
              })}
            </>
          }
          {!isMobile &&
            <Link to="/">
              <Avatar
                size={{ xs: 35, sm: 35, md: 35, lg: 35, xl: 40, xxl: 45 }}
                src={"data:image/png;base64," + instituteInfo?.imageName}
                style={{ marginLeft: 10 }}
              />
            <span style={{ fontSize: isMobile ? 14 : 18, fontWeight: "bold", color: "#0078CF", textTransform: "uppercase", letterSpacing: isMobile ? 0 : 1, marginLeft: 5 }}>{instituteInfo?.instituteName}</span>
            </Link>
          }
        </div>

      {isMobile && props.value.collapsed &&   <div style={{marginRight:isMobile?20:50, position:"absolute", right:50, top:10}}>
          <Popover
            content={duepopHover()}
            placement="bottomLeft"
            trigger="click"
     
          >
            <Badge count={count}  style={{ backgroundColor: dueAmount>0?'red':'#52c41a', top:6, right:4 }} >
            <BellOutlined style={{fontSize:30, color:"#2243CE", }} />
            {/* <Avatar
                icon={<BellOutlined  />}
                size="large"
                style={{ backgroundColor: dueAmount > 0 ? 'transparent' : 'transparent', borderColor: dueAmount > 0 ? 'red' : '#52c41a' }}
                className="pointer topUserImage"
              /> */}
            </Badge>
          </Popover>
        </div>   }  
        {!isMobile  &&  <div style={{marginRight:isMobile?20:50, position:"absolute", right:50, top:10}}>
          <Popover
            content={duepopHover()}
            placement="bottomLeft"
            trigger="click"
     
          >
            <Badge count={count}  style={{ backgroundColor: dueAmount>0?'red':'#52c41a', top:6, right:4 }} >
            <BellOutlined style={{fontSize:30, color:"#2243CE", }} />
            {/* <Avatar
                icon={<BellOutlined  />}
                size="large"
                style={{ backgroundColor: dueAmount > 0 ? 'transparent' : 'transparent', borderColor: dueAmount > 0 ? 'red' : '#52c41a' }}
                className="pointer topUserImage"
              /> */}
            </Badge>
          </Popover>
        </div>   }     
        <div>
          <Popover
            content={profilePopover(logoutfunc, user)}
            placement="bottomLeft"
            trigger="click"
          >
            {instituteInfo?.userPhoto === '' ?
              <Avatar
                icon={<UserOutlined  />}
                size="large"
                style={{ marginRight: 5 }}
                className="pointer topUserImage"
              /> :
              <Avatar
                size="large"
                src={"data:image/png;base64," + instituteInfo?.userPhoto}
                style={{ marginRight: 5 }}
                className="pointer topUserImage"
              />}
          </Popover>
        </div>
      </div>

    </>
  );
}