export const ROUTES = {
  DEFAULT: "/",
  
  LEDGER_LIST: "ledger-list",
  RECEIVE_TRN_TRANSACTION: "receive-trn-transaction",
  PAYMENT_TRN_TRANSACTION: "payment-trn-transaction",
  CONTRA_TRN_TRANSACTION: "contra-trn-transaction",
  JOURNAL_TRN_TRANSACTION: "journal-trn-transaction",
  DELETE_VOUCHER_GAVOUCHER: "delete-voucher-gavoucher",
  TRIAL_BALANCE_GAREPORT: "trial-balance-gareport",
  BALANCE_SHEET_GAREPORT: "balance-sheet-gareport",
  INCOME_STATEMENT_GAREPORT: "income-statement-gareport",
  JOURNAL_GAREPORT: "journal-gareport",
  FUNDS_FLOW_GAREPORT: "funds-flow-gareport",
  CASH_SUMMARY_GAREPORT: "cash-summary-gareport",
  USER_WISE_TRAN: "user-wise-tran-gareport",
  VOUCHER_TEMPLATE: "voucher-template-gareport",
  ACCOUNT_BOOK: "account-book-gareport",
  STUDENT_WRAPPER: "student-setting",
  STUDENT_CLASS_CONFIG: "initial-setup-class-configuration",
  CLASS_SEMESTER_CONFIG: "initial-setup-group-configuration",
  STUDENT_MULTIPLE_REGISTRATION: "student-multiple-registration",
  STUDENT_MULTIPLE_REGISTRATION_EXCEL: "student-multiple-registration-excel",
  STUDENT_BASIC_UPDATE: "student-basic-update",
  STUDENT_MIGRATION: "student-migration",
  STUDENT_PHOTO_UPDATE: "student-photo-update",
  STUDENT_ACADEMICINFO_UPDATE: "student-academic-info-update",
  STUDENT_ADDRESS_UPDATE: "student-address-update",
  STUDENT_REGISTRATION_DELETE:"student-registration-delete",
  STUDENT_ID_CARD_TEMPLATE: "student-id-card-template",
  STUDENT_ID_CARD_TEMPLATE_DOWNLOAD: "student-id-card-download",
  CLASS_WISE_REPORT: "student-report-basic-info-list-class-wise",
  SECTION_WISE_STUDENT_REPORT: "student-report-basic-info-list",
  GROUP_WISE_STUDENT_REPORT: "student-report-basic-info-list-group-wise",
  TAUGHT_LIST_STUDENT_REPORT: "student-report-basic-info-list-taught-list",
  SECTION_WISE_SUMMARY_REPORT: "student-report-section-wise-summary",
  SUBJECT_WISE_SUMMARY_REPORT: "student-report-subject-wise-summary",
  SECTION_OVERVIEW_REPORT: "student-report-overview",
  STUDENT_SINGLE_SEARCH: "student-single-search",
  STUDENT_UPDATE_ID: "student-id-update",
  STUDENT_ENABLED_DISABLED: "student-enable-disable",
  GENERAL_STUDENT_MIGRATION: "general-student-migration",
  MERIT_STUDENT_MIGRATION: "merit-student-migration",
  STUDENT_REPORT_BASIC_INFO_CATEGORY_WISE_REPORT: "student-report-basic-info-list-category-wise",

  /*******HR Attendance*/
  HR_ATTENDANCE_TAKE: "hr-attendance-take",
  HR_ATTENDANCE_UPDATE: "hr-attendance-update",
  HR_SINGLE_LEAVE: "hr-single-leave",
  HR_TIME_CONFIG: "hr-time-config",
  HR_TD_MAPPING: "hr-id-mapping",
  HR_FILE_UPLOAD: "hr-file-upload",
  HR_REPORT_TIME_CONFIG: "hr-report-time-config",
  HR_REPORT_DATE_WISE: "hr-report-date-wise",
  HR_REPORT_MONTH_WISE: "hr-report-month-wise",
  HR_REMARK_DATE_WISE: "hr-remark-date-wise",
  HOLIDAY: "holiday",
  /*******HR Attendance*/

  STAFF_SETTINGS: "staff-settings",
  STAFF_SINGLE_REGISTRATION: "staff-single-registration",
  STAFF_MULTIPLE_REGISTRATION: "staff-multiple-registration",
  STAFF_UPDATE: "staff-update-update",
  STAFF_ASSIGN: "staff-assign-teacher",
  STAFF_LIST: "staff-list",
  STAFF_TEMPLATE: "staff-template",
  STAFF_ID_TEMP: "staff-id-template",
  
  FEE_HEAD: "fee-head-settings",
  FEE_SUB_HEAD: "fee-sub-head-settings",
  FEE_HEAD_WAIVER: "fee-head-waiver-settings",
  FEE_HEAD_LEDGER_CONFIGUARTION: "fee-head-ledger-configuration",
  FEE_SUBHEAD_TIME_CONFIGUARTION: "fee-subhead-time-configuration",
  OFPS_ACCOUNT_CONFIG: "ofps-account-configuration",
  FEE_SUB_HEAD_LEDGER_CONFIGUARTION: "fee-sub-head-ledger-configuration",
  FEE_FINE_LEDGER_CONFIGUARTION: "fee-fine-ledger-configuration",
  FEE_AMOUNT_CONFIGUARTION: "fee-amount-configuration",
  FEE_WAIVE_CONFIGUARTION: "fee-waive-configuration",
  FEE_HEAD_DELETE_CONFIGUARTION: "fee-head-delete-configuration",
  FEE_COLLECTION_MANUAL: "fee-collection-manual",
  FEE_REPORT_SECTION_PAID_LIST: "fee-report-section-paid-list",
  FEE_REPORT_CLASS_PAID_LIST: "fee-report-class-paid-list",
  FEE_REPORT_ALL_PAID_LIST: "fee-report-all-paid-list",
  FEE_REPORT_SECTION_DUE: "fee-report-section-due",
  FEE_REPORT_MONTHLY_DUE: "fee-report-monthly-due",
  FEE_REPORT_FEE_COLLECTION: "fee-report-feehead-collection",
  FEE_REPORT_FEE_COLLECTION_CLASS: "fee-report-feehead-collection-class",
  FEE_DELETE_INVOICE: "fee-invoice-delete",
  FEE_DISCARD: "fee-discard",
  FEE_SUBHEAD_DISCARD: "fee-subhead-discard",
  SPG_STATEMENT: "spg-statement",
  MONEY_RECIPT_TEMPLATE: "fee-money-receipt-template",
  USER_LIST: "user-list",
  INITIAL_SETUP_PERIOD: "initial-setup-period",
  STUDENT_ATTENDANCE_PERIOD_TIME_CONFIGURATION: "student-attendance-period-time-configuration",
  STUDENT_DEVICE_ID_MAPPING: "student-device-id-mapping",
  STUDENT_ATTENDANCE_INPUT: "student-attendance-input",
  STUDENT_ATTENDANCE_UPDATE: "student-attendance-update",
  STUDENT_ATTENDANCE_EXAM: "student-attendance-exam",
  STUDENT_LEAVE_INPUT: "student-leave-input",
  DAILY_ATTENSION_REPORT_DAILY_SECTION: "student-attendance-report-daily-section",
  DAILY_ATTENSION_REPORT_SECTION_WISE_DAILY_SUMMARY: "student-attendance-report-section-wise-daily-summary",
  STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_DETAILS: "student-attendance-report-monthly-section-details",
  STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_SUMMARY: "student-attendance-report-monthly-section-summary",
  STUDENT_ATTENDANCE_MACHINE_INPUT: "student-attendance-machine-input",
  /*******Exam*********/
  EXAM_SETTING: "exam-setting",
  EXAM_SUBJECT_CONFIGURATION: "exam-subject-configuration",
  EXAM_CONFIGURATION: "exam-configuration",
  EXAM_MARK_CONFIGURE: "exam-mark-configuration",
  EXAM_GRANDFINAL_MARK_CONFIGURE: "exam-grandfinal-mark-configuration",
  EXAM_FOUR_SUBJECT_ASSIGN: "exam-forth-subject-assign",
  ADMIT_CARD_INSTRUCTION: "admit-card-instruction",
  EXAM_TEMPLATE: "exam-marksheet-template",
  ADMIT_CARD_SEAT_PLAN: "admit-card-seat-plan-download",
  EXAM_MARK_INPUT: "exam-mark-input",
  EXAM_MARK_UPDATE: "exam-mark-update",
  EXAM_MARK_DELETE: "exam-mark-delete",
  EXAM_RESULT_PUBLISH: "exam-result-publish",
  EXAM_GENERAL_RESULT_PROCESS: "exam-result-process-general",
  EXAM_GENERAL_RESULT_PROCESS_GRAND_FINAL: "exam-result-process-grand-final",
  EXAM_GENERAL_RESULT_MERIT_POSITION: "exam-merit-position-generate",
  EXAM_REMARKS_GIVE: "exam-remarks-give",
  EXAM_GENERAL_TEBULATION_SHEET: "exam-general-tabulation-sheet-download",
  EXAM_GENERAL_MARKSHEET_DOWNLOAD: "exam-general-marksheet-download",
  EXAM_GRAND_MARKSHEET_DOWNLOAD: "exam-grand-final-marksheet-download",
  EXAM_REPORT_SECTION_WISE_UNINPUTTED_SUBJECTS: "exam-report-section-wise-un-inputted-subjects",
  EXAM_REPORT_SECTION_RESULT: "exam-report-section-result",
  EXAM_REPORT_SECTION_WISE_MERIT_LIST: "exam-report-section-merit-position",
  EXAM_REPORT_CLASS_WISE_MERIT_LIST: "exam-report-class-merit-position",
  EXAM_REPORT_CLASS_FAILED: "exam-report-class-failed-list",
  EXAM_REPORT_CLASS_MERIT: "exam-report-class-merit-list",
  EXAM_REPORT_SECTION_FAILED: "exam-report-section-failed-list",
  EXAM_REPORT_SECTION_WISE_GRADING_SUMMARY: "exam-report-section-wise-grading-summary",
  EXAM_REPORT_SECTION_WISE_PASS_FAIL_SUMMARY: "exam-report-section-wise-pass-fail-summary",
  /****************/

  /*****Payslip******/
  PAYSLIP_CREATE_SINGLE:"payslip-create-single",
  PAYSLIP_CREATE_BATCH:"payslip-create-batch",
  PAYSLIP_COLLECT:"payslip-collect",
  PAYSLIP_TEMPLATE:"payslip-template",
  PAYSLIP_DATE_UPDATE:"payslip-date-update",
  /**************/

  /*****SMS******/
  SMS_SEND_DYNAMIC:"sms-send-dynamic",
  STAFF_SMS:"sms-send-to-staff",
  STUDENT_CLASSWISE_SMS:"send-sms-to-student-classwise",
  STUDENT_SECTIONWISE_SMS:"send-sms-to-student-sectionswise",
  STUDENT_INSTIUTE_SMS:"send-sms-to-student-institutewise",
  SECTION_DUE_SMS:"send-sms-to-student-duewise",
  SMS_TEMPLATE:"sms-template",
  SMS_PURCHASE:"purchase-sms",
  GENERAL_SMS_LIST:"sms-report-general-view",
  STAFF_SMS_LIST:"sms-report-staff-view",
  STUDENT_SMS_LIST:"sms-report-student-view",
  SMS_BALANCE:"sms-balance",
  FEE_PAID_SMS:"fee-paid-sms",
  SEND_SMS_EXAM_RESULT:"send-sms-to-student-examresult",
  /**************/

  /*******Master Setting*/
  INSTITUTE_UPDATE: "institute-update",
  DEFAULT_SIGNATURE: "default-signature",
  MASTER_USER_LIST:"users-list",
  MASTER_PAYABLE_BILL_LIST:"payable-bill",
  MASTER_PAID_BILL_LIST:"paid-bill",
  /********/

  /*******TC/Testimonial*/
  TESTIMONIALS:"testimonial",
  TRANSFER_CERTIFICATE:"transfer",
  /********/
  /*******Customer Support*/
  INSTITUTE_LIST:"institute-list",
  OFPS_INSTITUTE_LIST:"ofps_institute-list",
  INSTITUTE_STUDENT_COUNT:"institute-student-count",
  INSTITUTE_JUMP:"institute-jump",
  EXAM_GRADE_UPDATE:"exam-grade-update",
  OFPS_PROBLEM:"ofps-problem",

   /*******Miscellaneous*/
   CLASS_ROUTINE:"class-routine",
   EXAM_ROUTINE:"exam-routine",
   /***********online admission*/
   SECTION_WISE_ONLINE_ADMISSION:"section-wise-online-admission",
   GROUP_WISE_ONLINE_ADMISSION:"group-wise-online-admission",
   PAYMENT_STATUS_WISE_ONLINE_ADMISSION:"payment-status-wise-online-admission",

   ERROR_PAGE:"error-page",
};
