import React, { useCallback, useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectAcademicYear4 } from '../../../select/SelectAcademicYear4';


export default function BasicUpdate() {

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListByClassConfigId);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListByClassConfigId);
    const check = useStoreState((state) => state.student.check);
    const updateStudentBasicInfo = useStoreActions((state) => state.student.updateStudentBasicInfo);
    const [section, setSection] = useState<any>();
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const [tableRowStore, setTableRowStore] = useState<any>([]);
    const loading = useStoreState((state) => state.student.loading);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };



    const searchStudentList = (value) => {
        setTableRowStore([])
        fetchStudentListByClassConfigId(value);
        setSection(value)
    }
    const updateStudentInfo = (value) => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            let updateValueArray: any = [];
            selectedValue.map((item: any) => {
                let updateData = {
                    studentId: item.studentId,
                    customStudentId: item.customStudentId,
                    bloodGroup: item.bloodGroup,
                    fatherName: item.fatherName,
                    studentGender: item.studentGender,
                    motherName: item.motherName,
                    registrationNo: item.registrationNo,
                    studentReligion: item.studentReligion,
                    guardianMobile: item.guardianMobile,
                    studentDOB: item.studentDOB,
                    studentName: item.studentName,
                    studentRoll: item.studentRoll,
                    identificationId: item.identificationId,
                    studentSession: item.studentSession,
                }
                updateValueArray.push(updateData);
            });
            updateStudentBasicInfo({ data: updateValueArray, id: section });
            setselectedRowKeys([]);
            fetchStudentListByClassConfigId(section);
            setTableRowStore(studentListByClassConfigId);
        } else {
            notification.error({ message: 'Select the table row first' });
        }
    }


    const onchangeValue: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        }, [tableRowStore])

    const onchangeValueDate: any =
        useCallback((key, data, index) => (e: any) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = moment(e._d).format("YYYY-MM-DD");
            setTableRowStore(newData);
        }, [tableRowStore])
    const onchangeValueDropDown: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            //console.log(e);
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        }, [tableRowStore])

    useEffect(() => {
        setTableRowStore(studentListByClassConfigId);
    }, [studentListByClassConfigId]);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="ID" style={{ width: 100 }} value={record.customStudentId} name="customStudentId" className="stdName" onChange={onchangeValue("customStudentId", record, record.index)} />
            ),
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            editable: true,
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Student Name" style={{ width: 180 }} value={record.studentName} name="studentName" className="stdName" onChange={onchangeValue("studentName", record, record.index)} />
            ),
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Roll" style={{ width: 60 }} value={record.studentRoll} name="studentRoll" className="stdName" onChange={onchangeValue("studentRoll", record, record.index)} />
            ),
        },
        {
            title: 'Registration No',
            dataIndex: 'registrationNo',
            key: 'registrationNo',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Registration No" style={{ width: 150 }} value={record.registrationNo} name="registrationNo" className="stdName" onChange={onchangeValue("registrationNo", record, record.index)} />
            ),
        },
        {
            title: 'Session',
            dataIndex: 'studentSession',
            key: 'studentSession',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <>
                    <Input placeholder="Session" value={record.studentSession} style={{ width: 150 }} name="studentSession" className="stdName" onChange={onchangeValue("studentSession", record, record.index)} />
                </>
            ),
        },
        {
            title: 'Father Name',
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Father Name" value={record.fatherName} style={{ width: 180 }} name="fatherName" className="stdName" onChange={onchangeValue("fatherName", record, record.index)} />
            ),
        },
        {
            title: 'Mother Name',
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Mother Name" value={record.motherName} style={{ width: 180 }} name="motherName" className="stdName" onChange={onchangeValue("motherName", record, record.index)} />
            ),
        },
        // {
        //     title: 'Religion',
        //     dataIndex: 'studentReligion',
        //     key: 'studentReligion',
        //     showOnResponse: true,
        //     showOnDesktop: true,
        //     render: (text, record, index) => (
        //         <Select
        //             placeholder="Select Religion"
        //             className="studentReligion"
        //             value={record.studentReligion}
        //             onChange={onchangeValueDropDown("studentReligion", record, record.index)}
        //             style={{ width: 130 }}
        //         >
        //             <Option value="Islam">Islam</Option>
        //             <Option value="Hinduism">Hinduism</Option>
        //             <Option value="Christian">Christian</Option>
        //             <Option value="Buddist">Buddist</Option>
        //             <Option value="Other">Other</Option>
        //         </Select>
        //     ),
        // },        
        {
            title: 'Religion',
            dataIndex: 'studentReligion',
            key: 'studentReligion',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div className="select-box">
                    <select
                        id="select-box1"
                        value={record.studentReligion}
                        onChange={onchangeValueDropDown("studentReligion", record, record.index)}
                        className='tableDropDown'
                    >
                        <option value="Islam">Islam</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Christian">Christian</option>
                        <option value="Buddist">Buddist</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            ),
        },
        // {
        //     title: 'Gender',
        //     dataIndex: 'studentGender',
        //     key: 'studentGender',
        //     showOnResponse: true,
        //     showOnDesktop: true,
        //     render: (text, record, index) => (
        //         <Select
        //             placeholder="Select Gender"
        //             className="studentGender"
        //             value={record.studentGender}
        //             onChange={onchangeValueDropDown("studentGender", record, record.index)}
        //             style={{ width: 130 }}
        //         >
        //             <Option value="Male">Male</Option>
        //             <Option value="Female">Female</Option>
        //         </Select>
        //     ),
        // },        
        {
            title: 'Gender',
            dataIndex: 'studentGender',
            key: 'studentGender',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div className="select-box">
                    <select
                        id="select-box1"
                        value={record.studentGender}
                        onChange={onchangeValueDropDown("studentGender", record, record.index)}
                        className='tableDropDown'
                    >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
            ),
        },
        // {
        //     title: 'Blood Group',
        //     dataIndex: 'bloodGroup',
        //     key: 'bloodGroup',
        //     showOnResponse: true,
        //     showOnDesktop: true,
        //     render: (text, record, index) => (
        //         <Select
        //             placeholder="Select Blood Group"
        //             className="studentGender"
        //             value={record.bloodGroup}
        //             onChange={onchangeValueDropDown("bloodGroup", record, record.index)}
        //             style={{ width: 130 }}
        //         >
        //             <Option value="A+">A</Option>
        //             <Option value="A-">A-</Option>
        //             <Option value="AB+">AB</Option>
        //             <Option value="AB-">AB-</Option>
        //             <Option value="B+">B+</Option>
        //             <Option value="B-">B-</Option>
        //             <Option value="O+">O+</Option>
        //             <Option value="O-">O-</Option>
        //         </Select>
        //     ),
        // },        
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div className="select-box">
                    <select
                        id="select-box1"
                        value={record.bloodGroup}
                        onChange={onchangeValueDropDown("bloodGroup", record, record.index)}
                        className='tableDropDown'
                    >
                        <option value="A+(ve)">A+(ve)</option>
                        <option value="A-(ve)">A-(ve)</option>
                        <option value="AB+(ve)">AB+(ve)</option>
                        <option value="AB-(ve)">AB-(ve)</option>
                        <option value="B+(ve)">B+(ve)</option>
                        <option value="B-(ve)">B-(ve)</option>
                        <option value="O+(ve)">O+(ve)</option>
                        <option value="O-(ve)">O-(ve)</option>
                    </select>
                </div>
            ),
        },

        {
            title: 'Date of Birth',
            dataIndex: 'studentDOB',
            key: 'studentDOB',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (<>
                {record.studentDOB != null ?
                    <DatePicker value={(moment(record?.studentDOB?.split('-').join('/')))} onChange={onchangeValueDate("studentDOB", record, record.index)} style={{ width: 150 }} />
                    :
                    <DatePicker value={null} onChange={onchangeValueDate("studentDOB", record, record.index)} style={{ width: 150 }} />
                }
            </>
            ),
        },

        {
            title: 'Mobile',
            dataIndex: 'guardianMobile',
            key: 'guardianMobile',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Mobile No" style={{ width: 150 }} defaultValue={record.guardianMobile} name="guardianMobile" className="stdName" onChange={onchangeValue("guardianMobile", record, record.index)} />
            ),
        },


    ];



    return (
        <>
            <Card title="Student Basic Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear4 />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => setSection(e)} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                {studentListByClassConfigId === null && <Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {studentListByClassConfigId !== null &&
                    <>

                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                            {/* <Table bordered={true} dataSource={studentListByClassConfigId} columns={columns} className="p-datatable-responsive-demo" /> */}



                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection: rowSelection,
                                    columns,
                                    dataSource: tableRowStore,
                                    filterData: tableRowStore,
                                    loading: check,
                                    pagination: { defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '20', '25'] },
                                    bordered: true,
                                    rowKey: "studentId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>

                        <Row >
                            <Col span="24">
                                <Button type="primary" icon={<EditOutlined />} style={{ float: 'right' }} onClick={(e) => updateStudentInfo(e)}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            </Card>
        </>
    )
}