import React from 'react'
import { Card, Tabs } from 'antd';
import FeeHeadCollectionDateWise from './FeeHeadCollectionDateWise';
import FeeHeadCollectionClass from './FeeHeadCollectionClass';
import FeeHeadCollectionStudent from './FeeHeadCollectionStudent';


export default function StaffIdMappingWrapper(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Fee Head Collection">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Date Wise" key="1">
                        <FeeHeadCollectionDateWise />
                    </TabPane>
                    <TabPane tab="Class Wise" key="2">
                        <FeeHeadCollectionClass />
                    </TabPane>
                    <TabPane tab="Student Wise" key="3">
                        <FeeHeadCollectionStudent />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}